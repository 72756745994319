let Reflux = require("reflux")

let Actions = Reflux.createActions([
  "loadStart",
  "loadComplete",
  "openSideMenu",
  "closeSideMenu",
  "projectDetailShow",
  "projectDetailHide"
])

export default Actions
