import React from "react"

import styled from "styled-components/macro"

import { useWindowScroll } from "react-recipes"
import { useAppContext } from "../../context/AppContext"

import menu from "../../assets/svg/menu.svg"
import close from "../../assets/svg/close.svg"

export default function SideMenuButton() {
  const { sideMenuShow, toggleSideMenuShow } = useAppContext()
  const { y } = useWindowScroll()
  const active = y > 200
  return (
    <Button
      className="reset-btn home-btn"
      onClick={toggleSideMenuShow}
      style={{
        width: "90px",
        opacity: active ? 1 : 0,
        pointerEvents: active ? "auto" : "none",
      }}
    >
      <img
        src={sideMenuShow ? close : menu}
        style={{ width: `${sideMenuShow ? 22 : 30}px` }}
        alt="Menu"
      />
    </Button>
  )
}

const Button = styled.button`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.4s background-color ease-in, 0.3s opacity ease-out;

  cursor: pointer;
  z-index: 9999;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s background-color ease-out;
  }

  img {
    height: auto;
  }
`
