import { useCallback, useState } from "react"

const useToggle = (initial = false) => {
  const [on, setToggle] = useState(initial)

  const reset = useCallback(() => setToggle(initial), [initial])
  const toggle = useCallback(() => setToggle(prev => !prev), [])
  const toggleOn = useCallback(() => setToggle(true), [])
  const toggleOff = useCallback(() => setToggle(false), [])

  return {
    on,
    set: setToggle,
    reset,
    toggle,
    toggleOn,
    toggleOff,
  }
}

export default useToggle
