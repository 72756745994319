import React from "react"

import Social from "../components/Social"

import logo from "../../assets/svg/ja-small.svg"

const Footer = () => (
  <footer className="footer fade-in2">
    <div className="footer-contact">
      <div className="ja-small">
        <img alt="Ja Tecson" src={logo} />
      </div>
      <div className="v-rule" />
      <div className="contact-link">
        <a
          href="mailto:info@jatecson.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@jatecson.com
        </a>
      </div>
    </div>
    <div className="footer-social">
      <Social />
    </div>
  </footer>
)

export default Footer
