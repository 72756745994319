import React, { useRef, useEffect } from "react"

import { TweenMax } from "gsap"
import { Link } from "gatsby"
import { useAppContext } from "../../context/AppContext"

function PageNav(props) {
  const { homeTextLight } = useAppContext()
  const init = useRef(false)
  const projectRef = useRef()
  const imagesRef = useRef()
  const infoRef = useRef()

  const style = homeTextLight ? { color: "#fff" } : { color: "#260102" }

  useEffect(() => {
    if (!init.current) {
      TweenMax.from([projectRef.current, imagesRef.current, infoRef.current], {
        duration: 0.3,
        y: "-100%",
        ease: "expo.out",
        delay: 0.5,
        stagger: 0.05,
      })

      init.current = true
    }
  })

  return (
    <div className="nav">
      <div className="nav-item" style={style}>
        <Link ref={projectRef} to="/projects">
          Projects
        </Link>
      </div>
      <div className="nav-item" style={style}>
        <Link ref={imagesRef} to="/images">
          Images
        </Link>
      </div>
      <div className="spacer" />
      <div className="nav-item" style={style}>
        <Link ref={infoRef} to="/information">
          Info<span className="hide-450">rmation</span>
        </Link>
      </div>
    </div>
  )
}

export default PageNav
