import React from "react"
import createClass from "create-react-class"

import Actions from "../actions/Actions"
import { gsap, TweenMax } from "gsap"
import Social from "../components/Social"
import { navigate } from "gatsby"
import { useAppContext } from "../../context/AppContext"
import { delay } from "lodash"
import ease from "../constants/ease"

import menuBg from "../../assets/svg/menu-bg.svg"

function SideMenu() {
  const { setSideMenuHide, sideMenuShow } = useAppContext()
  return (
    <SideMenuContent
      setSideMenuHide={setSideMenuHide}
      showMenu={sideMenuShow}
    />
  )
}

const SideMenuContent = createClass({
  getInitialState() {
    return {
      menuX: 0,
    }
  },

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)

    this.getMenuWidth()

    TweenMax.set(this.sideMenuContainer, { autoAlpha: 0 })
    this.closeMenuClick()

    //this.onMenuOpen()
  },

  componentDidUpdate(prevProps) {
    const { showMenu } = this.props
    if (prevProps.showMenu !== this.props.showMenu) {
      if (showMenu) this.onMenuOpen()
      else this.closeMenuClick()
    }
  },

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  },

  getMenuWidth() {
    let w = window.innerWidth
    let menuX = w < 500 ? 90 : w * 0.5
    this.setState({ menuX })
  },

  handleResize() {
    this.getMenuWidth()

    if (this.props.showMenu) {
      let sideMenu = this.sideMenu
      TweenMax.set(sideMenu, { x: this.state.menuX })
    }
  },

  onMenuOpen() {
    this.setState({ showMenu: true })

    let sideMenu = this.sideMenu,
      sideMenuContainer = this.sideMenuContainer

    let tl = gsap.timeline()
    tl.to(
      sideMenu,
      0.5,
      {
        x: this.state.menuX,
        ease: ease("outExpo"),
      },
      "in"
    )
    tl.to(
      sideMenuContainer,
      0.2,
      {
        autoAlpha: 1,
        ease: ease("outQuad"),
      },
      "in"
    )
    tl.play()

    document.body.style.overflow = "hidden"
  },

  closeMenuClick() {
    Actions.closeSideMenu()
    this.setState({ showMenu: false })

    let sideMenu = this.sideMenu,
      sideMenuContainer = this.sideMenuContainer

    let tl = gsap.timeline()
    tl.to(
      sideMenu,
      0.5,
      {
        x: window.innerWidth,
        ease: ease("inOutExpo"),
      },
      "out"
    )
    tl.to(
      sideMenuContainer,
      0.5,
      {
        autoAlpha: 0,
        ease: ease("inOutQuad"),
      },
      "out"
    )
    tl.play()

    document.body.style.overflow = "auto"
  },

  closeMenuAndGo(link) {
    this.closeMenuClick()
    delay(() => navigate(link), 200)
  },

  onClickGoToProjects() {
    this.closeMenuAndGo("projects")
  },

  onClickGoToImages() {
    this.closeMenuAndGo("images")
  },

  onClickGoToInfo() {
    this.closeMenuAndGo("information")
  },

  render() {
    return (
      <div
        key="side-menu"
        className="side-menu"
        ref={i => (this.sideMenuContainer = i)}
      >
        <button
          className="reset-btn close-bg-btn"
          onClick={this.props.setSideMenuHide}
        />
        <div
          ref={i => (this.sideMenu = i)}
          className="sm-content"
          style={{ backgroundImage: `url(${menuBg})` }}
        >
          <div className="side-top">
            <button className="reset-btn" onClick={this.onClickGoToProjects}>
              <div className="nav-item">
                <div className="txt-link">Projects</div>
              </div>
            </button>
            <button className="reset-btn" onClick={this.onClickGoToImages}>
              <div className="nav-item">
                <div className="txt-link">Images</div>
              </div>
            </button>
            <div className="blue-rule" />
            <button className="reset-btn" onClick={this.onClickGoToInfo}>
              <div className="nav-item-small">
                <div className="txt-link">Information</div>
              </div>
            </button>
          </div>
          <div className="side-bottom">
            <Social />
          </div>
        </div>
      </div>
    )
  },
})

export default SideMenu
