import React, { useEffect, forwardRef, memo, useRef } from "react"
import { useAppContext } from "../../context/AppContext"
import { TweenMax, Power1 } from "gsap"

const GREY = "#D8D8D8"
const tweenProps = {
  duration: 0.3,
  ease: Power1.easeOut,
}

function Logo(props, svgRef) {
  const { logoGrey } = useAppContext()
  const path1Ref = useRef()
  const path2Ref = useRef()
  const path3Ref = useRef()

  useEffect(() => {
    TweenMax.to(path1Ref.current, {
      ...tweenProps,
      fill: logoGrey ? GREY : "#4FBBEC",
    })
    TweenMax.to(path3Ref.current, {
      ...tweenProps,
      delay: 0.1,
      fill: logoGrey ? GREY : "#3E1A30",
    })
    TweenMax.to(path2Ref.current, {
      ...tweenProps,
      delay: 0.2,
      fill: logoGrey ? GREY : "#C82434",
    })
  }, [logoGrey])

  return (
    <svg width={78} height={57} viewBox="0 0 78 57" ref={svgRef} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          ref={path1Ref}
          d="M33.562 18.928l-11.069 18.86H.334l11.078 18.879h22.161l11.07-18.86-.004-.006-11.077-18.873"
          fill={GREY}
        />
        <path
          ref={path2Ref}
          d="M55.721 18.928L44.654 37.787l-.012.02L55.72 56.682h22.16L55.72 18.928"
          fill={GREY}
        />
        <path
          ref={path3Ref}
          d="M44.641.048L33.573 18.907v.001l-.011.02 11.077 18.873.003.005.012-.019 11.067-18.86-.002-.005L44.64.048"
          fill={GREY}
        />
      </g>
    </svg>
  )
}

const ForwardRef = forwardRef(Logo)
const MemoForwardRef = memo(ForwardRef)
export default MemoForwardRef
