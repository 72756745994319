/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import AppProvider from "../context/AppContext"

import Header from "../components/nav/Header"
import Footer from "../components/nav/Footer"
import SideMenu from "../components/nav/SideMenu"

import useSSR from "use-ssr"
import { first } from "lodash"

import "../components/scss/main.scss"

const style = "background: #222; color: #ecb1ff; padding: 15px;"
console.log("%c Design & Development: Zero Style https://zero.style ", style)

const Layout = ({ children, path, ...props }) => {
  const paths = (path && path.split("/").filter(path => path !== "")) || []
  const hideHeader = first(paths) === "detail"

  const { isBrowser } = useSSR()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <AppProvider>
      {!hideHeader && <Header siteTitle={data.site.siteMetadata.title} />}
      <main className="wrapper">{children}</main>
      {!hideHeader && (
        <>
          <Footer />
          {isBrowser && <SideMenu />}
        </>
      )}
    </AppProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  grey: PropTypes.bool,
  hideHeader: PropTypes.bool,
}

export default Layout
