// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-images-js": () => import("./../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-detail-js": () => import("./../src/templates/detail.js" /* webpackChunkName: "component---src-templates-detail-js" */),
  "component---src-templates-set-js": () => import("./../src/templates/set.js" /* webpackChunkName: "component---src-templates-set-js" */)
}

