import React from "react"

import { Link } from "gatsby"
import PageNav from "./PageNav"
import SideMenuButton from "./SideMenuButton"
import Logo from "../nav/Logo"

import useSSR from "use-ssr"

function Header() {
  const { isBrowser } = useSSR()
  return (
    <>
      <header className="ja-header-home">
        <nav className="nav-container">
          <Link to="/" className="home-btn">
            <div className="mark">
              <Logo />
            </div>
          </Link>
          <PageNav />
        </nav>
      </header>
      {isBrowser && <SideMenuButton />}
    </>
  )
}

export default Header
