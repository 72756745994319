import React, { useEffect, useRef } from "react"
import { TweenMax } from "gsap"

import facebookIcon from "../../assets/svg/facebook.svg"
import instagramIcon from "../../assets/svg/instagram.svg"
import twitterIcon from "../../assets/svg/twitter.svg"
import vimeoIcon from "../../assets/svg/vimeo.svg"

export default function Social({ delay = 0.7 }) {
  const facebookRef = useRef()
  const instagramRef = useRef()
  const twittermRef = useRef()
  const vimeoRef = useRef()

  useEffect(() => {
    TweenMax.to(
      [
        facebookRef.current,
        instagramRef.current,
        twittermRef.current,
        vimeoRef.current,
      ],
      {
        duration: 0.5,
        y: 0,
        opacity: 1,
        ease: "power3.out",
        stagger: 0.05,
        delay,
      }
    )
  }, [])

  return (
    <>
      <div className="social-item c-hide">
        <div ref={facebookRef} className="img-link">
          <a
            href="https://www.facebook.com/ja.tecson"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Facebook" src={facebookIcon} />
          </a>
        </div>
      </div>
      <div className="social-item c-hide">
        <div ref={instagramRef} className="img-link">
          <a
            href="https://instagram.com/jatecson"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Instagram" src={instagramIcon} />
          </a>
        </div>
      </div>
      <div className="social-item c-hide">
        <div ref={twittermRef} className="img-link">
          <a
            href="https://twitter.com/jatecson"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Twitter" src={twitterIcon} />
          </a>
        </div>
      </div>
      <div className="social-item c-hide">
        <div ref={vimeoRef} className="img-link">
          <a
            href="https://vimeo.com/user13335604"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Vimeo" src={vimeoIcon} />
          </a>
        </div>
      </div>
    </>
  )
}
