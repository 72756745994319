import React, { createContext, useContext, useMemo } from "react"
import PropTypes from "prop-types"

import useToggle from "../hooks/useToggle"

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const {
    on: sideMenuShow,
    toggle: toggleSideMenuShow,
    toggleOn: setSideMenuShow,
    toggleOff: setSideMenuHide,
  } = useToggle(false)

  const {
    on: logoGrey,
    toggleOn: setLogoGrey,
    toggleOff: setLogoColored,
  } = useToggle(true)

  const {
    on: homeTextLight,
    toggleOn: setHomeTextLight,
    toggleOff: setHomeTextDark,
  } = useToggle(false)

  const value = useMemo(
    () => ({
      homeTextLight,
      setHomeTextLight,
      setHomeTextDark,
      logoGrey,
      setLogoGrey,
      setLogoColored,
      sideMenuShow,
      toggleSideMenuShow,
      setSideMenuShow,
      setSideMenuHide,
    }),
    [
      homeTextLight,
      setHomeTextLight,
      setHomeTextDark,
      logoGrey,
      setLogoGrey,
      setLogoColored,
      sideMenuShow,
      toggleSideMenuShow,
      setSideMenuShow,
      setSideMenuHide,
    ]
  )
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
